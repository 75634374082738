export function handleOnListChange(position, listState, setListState, value) {
    var list = [...listState];
    list[position] = value;
    setListState(list);
};

export function rollDie(toRoll) {
    var rollValues = dieParcer(toRoll);
    var roll = 0;
    for (let i = 0; i < rollValues.diceNumber; i++) {
        roll += getRandomInt(rollValues.diceKind);
    }
    roll += parseInt(rollValues.modifier);
    return roll;
};

export function createRoll(amount, die, modifier) {
    var strModifier = '';
    if (modifier >= 0) {
        strModifier = '+' + modifier;
    } else {
        strModifier = '' + modifier;
    }
    return '' + amount + 'd' + die + strModifier;
}

export function dieParcer(toParse) {
    var result = {diceNumber: 0, diceKind: 0, modifier: 0}
    //1d4 1d4-1 1d4+1
    var dieSplit = toParse.split('d');
    var plusSplit = dieSplit[1].split('+');
    var minusSplit = dieSplit[1].split('-');
    result.diceNumber = dieSplit[0]
    if (plusSplit.length === minusSplit.length) {
        result.diceKind = plusSplit[0];
    } else if (plusSplit.length > minusSplit.length) {
        result.diceKind = plusSplit[0];
        result.modifier = plusSplit[1];
    } else {
        result.diceKind = minusSplit[0];
        result.modifier = -1*minusSplit[1];
    }
    return result;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1;
};

export function checkSkillRollResult(skillValue, modifier) {
    var rolled = getRandomInt(100);
    var result = {result: "", reason: "", roll: 0, modifiedSkillValue: ''}
    result.roll = rolled;
    var toBeat = Math.ceil(skillValue * modifier);
    result.modifiedSkillValue = toBeat;
    if (rolled >= 99) {
        result.result = "Critically Failed!";
        result.reason = "Roll is 99-100";
    } else if (rolled >= 96) {
        result.result = "Failed.";
        result.reason = "Roll is 96-98";
    } else if (rolled <= Math.ceil(toBeat * .1)) {
        result.result = "Critically Succeeded!";
        result.reason = "Roll is less than or equal to 10% of target value " + toBeat;
    } else if (rolled <= 5) {
        result.result = "Succeeded.";
        result.reason = "Roll 1-5";
    } else if (rolled <= toBeat) {
        result.result = "Succeeded.";
        result.reason = "Roll is less than or equal to target value " + toBeat;
    } else if (rolled > toBeat) {
        result.result = "Failed.";
        result.reason = "Roll is greater than target value " + toBeat;
    }
    return result;
};

export function handleSkillRoll(rollDifficulty, skillValue) {
    var result = {result: "", reason: "", roll: 0, modifiedSkillValue: ''};
    if (rollDifficulty === "automatic") {
        result.result = "Succeeded.";
        result.roll = null;
        result.reason = "Automatically Succeeds.";
    } else if (rollDifficulty === "hopeless") {
        result.result = "Failed.";
        result.roll = null;
        result.reason = "Automatically Fails.";
    } else if (rollDifficulty === "veryeasy") {
        result = checkSkillRollResult(skillValue, 2);
    } else if (rollDifficulty === "easy") {
        result = checkSkillRollResult(skillValue, 1.5);
    } else if (rollDifficulty === "standard") {
        result = checkSkillRollResult(skillValue, 1);
    } else if (rollDifficulty === "hard") {
        result = checkSkillRollResult(skillValue, 2/3);
    } else if (rollDifficulty === "formidable") {
        result = checkSkillRollResult(skillValue, .5);
    } else if (rollDifficulty === "herculean") {
        result = checkSkillRollResult(skillValue, .25);
    }
    return result;
};

export function handleOnCheckboxChange(position, checkboxState, checkboxUpdater ){
    const updatedState = checkboxState.map((item, index) =>
      index === position ? !item : item
    );
    checkboxUpdater(updatedState);
  }