import React, { useState } from 'react';

function NumberInput( {position=0, valueList, setValueList, min, max, label='', column=null} ) {
  const [showError, setShowError] = useState(true);

  const handleOnValueListChange = (value) => {
    var list;
    if (column) {
      list = JSON.parse(JSON.stringify(valueList));
      if (Number.isInteger(value) && value >= min && value <= max) {
        list[position][column] = {value:parseInt(value), validation: null};
      } else {
        list[position][column] = {value:list[position][column].value, validation: `Value must be an integer between ${min} and ${max}!`};
        setShowError(true);
      }
      setValueList(list);
    } else {
      list = [...valueList];
      if (Number.isInteger(value) && value >= min && value <= max) {
        list[position] = {value:parseInt(value), validation: null};
      } else {
        list[position] = {value:list[position].value, validation: `Value must be an integer between ${min} and ${max}!`};
        setShowError(true);
      }
      setValueList(list);
    }


  }

  return (
    <>
        <div className='validation'>
            {`${label}`}
            {column ? 
              <>
                {valueList[position][column].validation &&
                  <span className={`validationtext ${showError? '': 'hidden'}`}>
                    <div className='col-11 no-padding'>
                      {valueList[position][column].validation}
                    </div>
                    <div className='col-1 no-padding'>
                      <button className='x-btn' onClick={e => setShowError(false)}>x</button>
                    </div>
                  </span>
                }
                <input type="number" min={min} max={max} onChange={e => handleOnValueListChange(parseInt(e.target.value))} defaultValue={valueList[position][column].value}/>
              </>
              : 
              <>
                {valueList[position].validation &&
                <span className={`validationtext ${showError? '': 'hidden'}`}>
                    <div className='col-11 no-padding'>
                      {valueList[position].validation}
                    </div>
                    <div className='col-1 no-padding'>
                      <button className='x-btn' onClick={e => setShowError(false)}>x</button>
                    </div>
                  </span>
                }
                <input type="number" min={min} max={max} onChange={e => handleOnValueListChange(parseInt(e.target.value))} defaultValue={valueList[position].value}/>
              </>
            }
        </div>
    </>
  );
}

export default NumberInput;
