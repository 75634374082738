import React from 'react';

export default function Home() {
  return(
    <div className='flex-container'> 
      <div className='flex-row'>
        <h2 className='col-12 no-margin page-header'>Home</h2>
        <hr></hr>
      </div>
  </div>
  );
}