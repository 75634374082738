import React from 'react';
import { Route} from 'react-router-dom';
import SkillCalc from './ToolsPages/SkillCalc';
import CombatLayout from './ToolsPages/CombatLayout';

import ToolsLanding from './ToolsPages/ToolsLanding';

export default function Tools() {
  return(
    <>
        <Route exact path="/tools/skillcalc">
            <SkillCalc />
        </Route>
        <Route exact path="/tools/combatLayout">
            <CombatLayout />
        </Route>
        <Route exact path="/tools">
            <ToolsLanding />
        </Route>
    </>
    
  );
}