var base;
if (process.env.NODE_ENV === 'development') {
    base = 'http://localhost:8080';
} else {
    base = 'https://api.expandingjourneys.com';
}
var tokenString;
var token;


export async function getRequest(path) {
    try {
        tokenString = localStorage.getItem('token');
        token = JSON.parse(tokenString).token;
    } catch (error) {
        token = ''
    };
    return fetch(base + path, {
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }).then(data => data.json())
    .then(json => ({body: json.body, status: json.statusCode}))
    .catch(() => ({body: {errorMessage: 'Could not reach server or understand response'}, status: 500}))
};

export async function postRequest(path, body) {
    try {
        tokenString = localStorage.getItem('token');
        token = JSON.parse(tokenString).token;
    } catch (error) {
        token = ''
    };
    return fetch(base + path, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(body)
    }).then(data => data.json())
    .then(json => ({body: json.body, status: json.statusCode}))
    .catch(() => ({body: {errorMessage: 'Could not reach server or understand response'}, status: 500}))
};

export async function deleteRequest(path) {
    try {
        tokenString = localStorage.getItem('token');
        token = JSON.parse(tokenString).token;
    } catch (error) {
        token = ''
    };
    return fetch(base + path, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }).then(data => data.json())
    .then(json => ({body: json.body, status: json.statusCode}))
    .catch(() => ({body: {errorMessage: 'Could not reach server or understand response'}, status: 500}))
};