import React from 'react';
import { useHistory } from "react-router-dom";
import './Logout.css'

export default function Logout( {setToken} ) {
  const history = useHistory();

  function handleClick( ) {
    setToken();
    localStorage.removeItem('token')
    history.push("/");
  }

  return (
    <button className="logout_button" type="button" onClick={handleClick}>
      Logout
    </button>
  );
}