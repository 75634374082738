import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from "react-router-dom";
import TextInput from '../Common/TextInput';
import { loginUser } from '../../apis/account';

export default function Login({ setToken }) {
  const [username, setUserName] = useState([{value: '', validation: null}]);
  const [password, setPassword] = useState([{value: '', validation: null}]);
  const [error, setError] = useState('');
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await loginUser({
      username: username[0].value,
      password: password[0].value
    });
    if (response.status === 200) {
      setToken(response.body);
      if (location.pathname === '/login') {
        history.push("/");
      }
    } else {
      setError(response.body.errorMessage);
    }

  }

  useEffect(() => {
    setToken();
    localStorage.removeItem('token');
  }, [setToken]) 

  return(
    <div className="flex-container">
      <div className='flex-row'>
        <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Please Log In</h2>
      
      <hr></hr>
      <div className='flex-row center col-12 col-m-12 col-s-12 col-xs-12'>
        <div className='center flex-column col-3 col-m-3 col-s-5 col-xs-6'>
          <form onSubmit={handleSubmit}>
            Username:
            <TextInput valueList={username} setValueList={setUserName} validationFormat={'username'} length={20} label={''}/>
            Password:
            <TextInput valueList={password} setValueList={setPassword} validationFormat={'password'} type="password" label={''}/>
            {error}
            <div>
              <button className='text-btn no-left-margin' type="submit">Log In</button>
            </div>
          </form>
        </div>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};