import { deleteRequest, getRequest, postRequest } from "./main";

export function getAll() {
    return getRequest('/data/monsters/creation');
};

export function getHabitats() {
    return getRequest('/data/monsters/habitats');
};

export function getTypes() {
    return getRequest('/data/monsters/types');
};

export function getSimplified() {
    return getRequest('/monster/simplified')
};

export function getCustomMonster(monster) {
    return getRequest('/monster/' + monster)
};

export function deleteMonster(monster) {
    return deleteRequest('/monster/' + monster)
}

export function saveCustomMonster (data) {
    return postRequest('/monster/custom/create', data);
};

export function updateCustomMonster (data) {
    return postRequest('/monster/custom/update', data);
};

export function getFatigue() {
    return getRequest('/data/fatigue');
};