import React, { useState } from 'react';

function TextInput( {position=0, valueList, setValueList, validationFormat="none", length=0, label='', column=null, type="text", classes=''} ) {
  const [showError, setShowError] = useState(true);

  const handleOnValueListChange = (value) => {
    var list;
    var re;
    var message;
      if (validationFormat === "strict") {
        re = new RegExp(`^[\\w\\s_'-]{1,${length}}$`);
        message = `Value must be 1-${length} characters long and consist of letters numbers spaces _ - and '!`
      } else if (validationFormat === "size") {
        re = new RegExp(`^\\dx\\d$`);
        message = `Value must be in the format {number 1-9}x{number 1-9}`
      } else if (validationFormat === "range") {
        re = new RegExp(`^(?:0[1-9]|1[0-9]|20)\\-(?:0[1-9]|1[0-9]|20)$`);
        message = `Value must be in the format {number 01-20}-{number 01-20}`
      } else if (validationFormat === "die") {
        re = new RegExp(`^[1-9]d[1-9](?:\\+[1-9]\\d{0,2}|-[1-9]\\d{0,2})?$`);
        message = `Value must be in the format {number 1-9}d{1-999} with an optional +/- {1-999}`
      } else if (validationFormat === "email") {
        re = new RegExp(`^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$`);
        message = `Value must be a valid email.`
      } else if (validationFormat === "nonstrict") {
        re = new RegExp(`^[\\w\\s\\(\\):;.,’+*"/\\?!'_-]{1,${length}}$`);
        message = `Value must be 1-${length} characters long and consist of letters numbers spaces and the symbols ();:.,’+*"/?!'_-`
      } else if (validationFormat === "password") {
        re = new RegExp(`^(?=.*[\\d])(?=.*[!@#$%^&*])[\\w!@#$%^&*]{8,30}$`);
        message = `Value must be 8-30 characters long and consist of letters numbers and the following symbols !@#$%^&* and contain at least 1 symbol and number!`
      } else if (validationFormat === "username") {
        re = new RegExp(`^[\\w_-]{1,20}$`);
        message = `Value must be 1-20} characters long and consist of only letters, numbers, _ and -!`
      }
      if (column) {
        list = JSON.parse(JSON.stringify(valueList));
        if (validationFormat === "none" || re.test(value)) {
          list[position][column] = {value: value, validation: null};
        } else {
          list[position][column] = {value: list[position].value, validation: message};
          setShowError(true);
        }
        setValueList(JSON.parse(JSON.stringify(list)));
      } else {
        list = [...valueList];
        if (validationFormat === "none" || re.test(value)) {
          list[position] = {value: value, validation: null};
        } else {
          list[position] = {value: list[position].value, validation: message};
          setShowError(true);
        }
        setValueList(list);
      }
  }

  return (
    <>
        <div className='validation'>
            {`${label}`}
            {column ? 
              <>
                {valueList[position][column].validation &&
                  <span className={`validationtext ${showError? '': 'hidden'}`}>
                    <div className='col-11 no-padding'>
                      {valueList[position][column].validation}
                    </div>
                    <div className='col-1 no-padding'>
                      <button className='x-btn' onClick={e => setShowError(false)}>x</button>
                    </div>
                  </span>
                }
                {type === "textarea" ? 
                  <textarea className={classes}onChange={e => handleOnValueListChange(e.target.value)} defaultValue={valueList[position][column].value}/>
                :
                  <input className={classes} type={type} onChange={e => handleOnValueListChange(e.target.value)} defaultValue={valueList[position][column].value}/>
                }
              </>
              :
              <>
                {valueList[position].validation &&
                <span className={`validationtext ${showError? '': 'hidden'}`}>
                    <div className='col-11 no-padding'>
                      {valueList[position].validation}
                    </div>
                    <div className='col-1 no-padding'>
                      <button className='x-btn' onClick={e => setShowError(false)}>x</button>
                    </div>
                  </span>
                }
                {type === "textarea" ? 
                  <textarea className={classes} onChange={e => handleOnValueListChange(e.target.value)} defaultValue={valueList[position].value}/>
                  :
                  <input className={classes} type={type} onChange={e => handleOnValueListChange(e.target.value)} defaultValue={valueList[position].value}/>
                }
              </>
            }
        </div>
    </>
  );
}

export default TextInput;
