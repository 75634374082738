import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import TextInput from '../Common/TextInput';
import { sendAccountInfo } from "../../apis/account"

export default function Signup() {
  const [username, setUserName] = useState([{value: '', validation: null}]);
  const [email, setEmail] = useState([{value: '', validation: null}]);
  const [password, setPassword] = useState([{value: '', validation: null}]);
  const [repeatPassword, setRepeatPassword] = useState([{value: '', validation: null}]);
  const history = useHistory();
  const [error, setError] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await sendAccountInfo({
      username: username[0].value,
      password: password[0].value,
      email: email[0].value
    });
    if (response.status === 200) {
        history.push("/login");
    } else {
        setError(response.body.errorMessage)
    }
  }

  return(
    <div className="flex-container">
      <div className='flex-row'>
        <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Sign Up</h2>
        <hr></hr>
        <div className='flex-row center col-12 col-m-12 col-s-12 col-xs-12'>
          <div className='center flex-column col-3 col-m-3 col-s-5 col-xs-6'>
            <form onSubmit={handleSubmit}>
              Email:
              <TextInput valueList={email} setValueList={setEmail} validationFormat={'email'} label={''}/>
              Username:
              <TextInput valueList={username} setValueList={setUserName} validationFormat={'username'} length={20} label={''}/>
              Password:
              <TextInput valueList={password} setValueList={setPassword} validationFormat={'password'} type="password" label={''}/>
              Repeat Password:
              <TextInput valueList={repeatPassword} setValueList={setRepeatPassword} validationFormat={'password'} type="password" label={''}/>
              {error}
              <div>
                {password.value === repeatPassword.value ? 
                (
                    <button className='text-btn no-left-margin' type="submit">Sign Up</button>
                ):(
                    <>Passwords Must Match</>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}