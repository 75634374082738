import React from 'react';
import { Route} from 'react-router-dom';
import MonstersLanding from './MonsterPages/MonsterLanding';
import MonstersCreate from './MonsterPages/MonsterCreate';
import MonsterViewAll from './MonsterPages/MonsterViewAll';
import MonsterView from './MonsterPages/MonsterView';

export default function Monsters() {
  return(
    <>
        <Route exact path="/monsters/create">
          <MonstersCreate />
        </Route>
        <Route exact path="/monsters/update/:monsterId">
          <MonstersCreate />
        </Route>
        <Route exact path="/monsters/view/:monsterId">
          <MonsterView />
        </Route>
        <Route exact path="/monsters/view">
          <MonsterViewAll />
        </Route>
        <Route exact path="/monsters">
          <MonstersLanding />
        </Route>
    </>
  );
}